import { useEffect } from 'react'
import { redirectToAuth0Login } from 'frontastic/contexts/authV2UserContext/helpers'
import { useAuthV2User } from 'frontastic/contexts'
import { useRouter } from 'next/router'
import { Box } from '@chakra-ui/react'

const Login = () => {
  const { authZeroAccessToken } = useAuthV2User()
  const router = useRouter()

  useEffect(() => {
    if (authZeroAccessToken) {
      router.push('/')
    } else {
      redirectToAuth0Login('/')
    }
  }, [authZeroAccessToken])

  return <Box />
}

export default Login
